body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}

.animate-timer {
  position: relative;
  animation: animatetop 1s infinite;
}
@keyframes animatetop {
  from {
    top: -50px;
  }
  to {
    top: 20px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
